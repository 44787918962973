<template>
  <div class="login-page">
    <div class="login-main">
      <div class="login-form">
        <div class="site-name">
          <el-image
            src="https://carbontech.obs.cn-north-4.myhuaweicloud.com/carbontechNews/image/bannerMain.png"
          ></el-image>
          <span class="font-site-name">线上购票</span>
        </div>
        <div class="select-login-way">
          <el-tabs v-model="activeName">
            <el-tab-pane label="账密登录" name="first">
              <div class="password-login-form">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
                  <el-form-item prop="phone">
                    <el-input
                      placeholder="请输入手机号"
                      v-model="ruleForm.phone"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="password">
                    <el-input
                      type="password"
                      placeholder="请输入密码"
                      v-model="ruleForm.password"
                    ></el-input>
                  </el-form-item>
                  <div class="notes no-error-message-top">
                    登录/注册即代表同意<router-link to="/userAgreement">《用户协议》</router-link>
                    
                    及<router-link to="/privacyAgreement">《隐私政策》</router-link>
                  </div>
                  <el-button
                    class="login-submit"
                    type="primary"
                    @click="submitForm()"
                    >登录</el-button
                  >
                 
                    <div class="fotget-password" @click="goRegister()">
                      快速注册
                    </div>
                </el-form>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { login, account } from "@/api/index";
export default {
  name: "header",
  components: {},
  data() {
    return {
      activeName: "first",
      ruleForm: {},
      rules: {
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  mounted() {},
  methods: {
    login() {
      this.$router.push("/login");
    },
    handleSelect() {},
    submitForm() {
      let data = {
        password: this.ruleForm.password,
        username: this.ruleForm.phone,
        codeValue: "6666",
        codeKey: "6666",
      };
      login(data).then((res) => {
        console.log(res);

        localStorage.setItem("token", res.data.accessToken);
        this.getUserInfo();
        this.$router.push("/");
      });
    },
    getUserInfo() {
      account().then((res) => {
        console.log(res);
         window.sessionStorage.setItem("userInfo", JSON.stringify(res.data));
        this.$store.commit("setUserInfo", res.data);
      });
    },
    goRegister() {
      this.$router.push("/register");
    },
  },
};
</script>
<style lang="less" scoped>
.login-main {
  height: calc(100vh - 70px);
  min-height: 674px;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  .login-form {
    width: 460px;
    min-height: 400px;
    padding: 40px 0;
    border: 1px solid #eaeaea;
    -webkit-box-shadow: none;
    box-shadow: none;
    .site-name {
      margin-bottom: 32px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      line-height: 50px;
      cursor: pointer;
      .el-image {
        width: 160px;
        height: 40px;
      }
      .font-site-name {
        font-size: 2rem;
        font-weight: 700;
        color: #003e89;
      }
    }
    .select-login-way {
      /deep/ .el-tabs {
        .el-tabs__header {
          margin-bottom: 40px;
          .el-tabs__nav-wrap {
            .el-tabs__nav-scroll {
              display: flex;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              .el-tabs__active-bar {
                width: 80px;
                transform: translateX(220px);
                color: #003e89;
              }
            }
          }
        }
        .el-tabs__item.is-top.is-active {
          font-size: 1.2rem;
          color: #003e89;
          font-weight: 600;
        }
      }
      .password-login-form {
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        .el-form-item {
          width: 330px;
        }
        .notes {
          width: 330px;
          margin: 0 auto;
          text-align: left;
          font-size: 12px;
          color: #666;
          line-height: 27px;
          word-break: normal;
        }
        .login-submit {
          width: 330px;
          height: 45px;
          margin-top: 8px;
          font-size: 14px;
          color: #fff;
          background: #003e89;
          letter-spacing: 0;
          border-radius: 4px;
        }
        .fotget-password {
          width: 330px;
          margin: 10px auto 0;
          text-align: right;
          font-size: 12px;
          color: #999;
          cursor: pointer;
        }
      }
    }
  }
}
</style>